import {
  applySpec,
  ascend,
  descend,
  filter,
  flatten,
  gt,
  juxt,
  lte,
  pipe,
  propOr,
  sort,
} from 'ramda'

const eventsSort = pipe(
  applySpec({
    upcoming: pipe(
      filter(pipe(propOr(0, 'endTime'), lte(Date.now()))),
      sort(ascend(propOr(0, 'startTime'))),
    ),
    completed: pipe(
      filter(pipe(propOr(0, 'endTime'), gt(Date.now()))),
      sort(descend(propOr(0, 'startTime'))),
    ),
  }),
  juxt([propOr([], 'upcoming'), propOr([], 'completed')]),
  flatten,
)

export default eventsSort
