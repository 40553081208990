const computeFee = (
  fee = 0,
  discount = 0,
  subscriptionDiscount = 0,
  linkedCourseDiscount = 0,
) => {
  const finalDiscountWithSubscription =
    discount + ((100 - discount) * subscriptionDiscount) / 100
  const finalDiscount =
    finalDiscountWithSubscription +
    ((100 - finalDiscountWithSubscription) * linkedCourseDiscount) / 100
  const directDiscountedValue = Math.ceil(fee * (100 - discount)) / 100
  const subscriptionDiscountedValue =
    Math.ceil(fee * (100 - finalDiscountWithSubscription)) / 100

  return {
    original: (Math.ceil(fee * 100) / 100).toFixed(2),
    directDiscounted: directDiscountedValue.toFixed(2),
    final: (Math.ceil(fee * (100 - finalDiscount)) / 100).toFixed(2),
    discountPercentage: `${discount}%`,
    discountValue: (Math.ceil(fee * discount) / 100).toFixed(2),
    totalDiscountPercentage: `${finalDiscount}%`,
    totalDiscountValue: (Math.ceil(fee * finalDiscount) / 100).toFixed(2),
    subscriptionDiscountPercentage: `${subscriptionDiscount}%`,
    subscriptionDiscountValue: (
      Math.ceil(directDiscountedValue * subscriptionDiscount) / 100
    ).toFixed(2),
    linkedCourseDiscountPercentage: `${linkedCourseDiscount}%`,
    linkedCourseDiscountValue: (
      Math.ceil(subscriptionDiscountedValue * linkedCourseDiscount) / 100
    ).toFixed(2),
    isDiscounted: Boolean(finalDiscount),
    hasLinkedCourseDiscount: Boolean(linkedCourseDiscount),
    hasSubscriptionDiscount: Boolean(subscriptionDiscount),
    hasDirectDiscount: Boolean(discount),
  }
}

export default computeFee
