import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { useState } from 'react'

import { listVariants, NoItemsVariants, showMoreVariants } from './animation'
import ItemWrapper from './components/item-wrapper'
import { ListEl, NoItemsEl, ShowMoreEl } from './elements'

const List = ({ data, item, defaultCount, noItemsMessage, hideShowMore }) => {
  const [count, setCount] = useState(Number(defaultCount))

  return (
    <ListEl
      animate='visible'
      exit='hidden'
      initial='hidden'
      variants={listVariants}
    >
      {data.slice(0, count).map((entry, index) => (
        <ItemWrapper
          key={entry.key || entry.id}
          defaultCount={defaultCount}
          index={index}
        >
          {item(entry, index)}
        </ItemWrapper>
      ))}
      {count >= data.length ? (
        data.length ? null : (
          Boolean(noItemsMessage) && (
            <NoItemsEl as={motion.p} variants={NoItemsVariants}>
              {noItemsMessage}
            </NoItemsEl>
          )
        )
      ) : hideShowMore ? null : (
        <ShowMoreEl
          className='showMore'
          custom={{ count: Math.min(defaultCount, data.length) }}
          onClick={() => setCount((prevCount) => prevCount + defaultCount)}
          role='button'
          variants={showMoreVariants}
        >
          <p>Show More</p>
        </ShowMoreEl>
      )}
    </ListEl>
  )
}

export default List

List.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, key: PropTypes.string }),
  ).isRequired,
  defaultCount: PropTypes.number,
  item: PropTypes.func.isRequired,
  noItemsMessage: PropTypes.string,
  hideShowMore: PropTypes.bool,
}

List.defaultProps = {
  defaultCount: 3,
  noItemsMessage: 'No Items',
  hideShowMore: false,
}
