/* eslint-disable import/prefer-default-export */
import { motion } from 'framer-motion'
import styled from 'styled-components'

import { hFlex } from '../../../styles/style-helpers'
import { S05 } from '../../../styles/typography'

export const ListEl = styled(motion.div)``

export const ItemWrapperEl = styled(motion.div)``

export const EndOfListEl = styled(S05)`
  width: 100%;
  ${hFlex};
  color: #555;
  margin: 1.5em 0;

  &:before,
  &:after {
    content: '';
    width: 2em;
    height: 1px;
    background: #777;
    margin: 0 1em;
  }
`
export const NoItemsEl = styled(S05)`
  width: 100%;
  ${hFlex};
  color: #555;
  margin: 1em 0;

  &:before,
  &:after {
    content: '';
    width: 2em;
    height: 1px;
    background: #777;
    margin: 0 1em;
  }
`

export const ShowMoreEl = styled(motion.div)`
  width: 100%;
  ${hFlex};
  margin: 1.5em 0;
`
