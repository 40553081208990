/* eslint-disable import/prefer-default-export */
import { motion } from 'framer-motion'
import styled from 'styled-components'

import { accent } from '../../../styles/colors'
import { hFlex, square } from '../../../styles/style-helpers'

export const LoadingEl = styled.div`
  height: 75%;
  width: 100%;
  ${hFlex};
  justify-content: center;
`

export const PulseEl = styled(motion.div)`
  ${square('0.75em')};
  border-radius: 50%;
  background: ${accent};
  margin: 0 0.25em;
`
