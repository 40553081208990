import PropTypes from 'prop-types'

import { itemWrapperVariants } from '../animation'
import { ItemWrapperEl } from '../elements'

const ItemWrapper = ({ children, index, defaultCount }) => {
  return (
    <ItemWrapperEl
      custom={{ count: defaultCount, index }}
      variants={itemWrapperVariants}
    >
      <div>{children}</div>
    </ItemWrapperEl>
  )
}

export default ItemWrapper

ItemWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  defaultCount: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}
