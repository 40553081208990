import { LoadingEl, PulseEl } from '../elements/loading'

const Loading = () => {
  return (
    <LoadingEl>
      {Array.from({ length: 3 })
        .map((_, i) => i)
        .map((key, i) => (
          <PulseEl
            key={key}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              repeat: Infinity,
              repeatType: 'mirror',
              duration: 0.5,
              repeatDelay: 0.1,
              delay: i * 0.15,
            }}
          />
        ))}
    </LoadingEl>
  )
}

export default Loading
