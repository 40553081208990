/* eslint-disable import/prefer-default-export */

import isServer from '../../../utils/is-server'

export const listVariants = {
  hidden: { opacity: isServer() ? 1 : 0 },
  visible: { opacity: 1 },
}

export const itemWrapperVariants = {
  hidden: {
    opacity: isServer() ? 1 : 0,
    y: isServer() ? 0 : 25,
  },
  visible: ({ index, count }) => ({
    opacity: 1,
    transition: {
      delay: ((index % count) + 3) * 0.1,
      duration: 0.5,
      ease: 'easeOut',
    },
    y: 0,
  }),
}

export const showMoreVariants = {
  hidden: { opacity: isServer() ? 1 : 0 },
  visible: ({ count }) => ({
    opacity: 1,
    transition: {
      delay: (count + 3) * 0.1,
    },
  }),
}

export const endOfListVariants = {
  hidden: { opacity: isServer() ? 1 : 0 },
  visible: { opacity: 1 },
}

export const NoItemsVariants = {
  hidden: { opacity: isServer() ? 1 : 0 },
  visible: { opacity: 1 },
}
