/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

import { vFlex } from '../../../styles/style-helpers'

export const LoadFailedEl = styled.div`
  height: 100%;
  width: 100%;
  ${vFlex};
`
