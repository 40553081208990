const broadcastConfig = {
  types: [
    {
      fullName: 'Live Masterclass',
      name: 'Masterclass',
      route: 'masterclass',
      type: 'master-class',
    },
    {
      fullName: 'Live Workshop',
      name: 'Workshop',
      route: 'workshop',
      type: 'workshop',
    },
    {
      fullName: 'Demo Session',
      name: 'Demo Session',
      route: 'demo',
      type: 'demo',
    },
    {
      fullName: 'Behind the Music Session',
      name: 'Behind The Music',
      route: 'btm',
      type: 'btm',
    },
    {
      fullName: 'Question & Answer Session',
      name: 'QnA',
      route: 'qna',
      type: 'qna',
    },
    {
      fullName: 'Live Concert',
      name: 'Concert',
      route: 'concert',
      type: 'concert',
    },
    {
      fullName: 'Live Gig',
      name: 'Gig',
      route: 'gig',
      type: 'gig',
    },
  ],
}

export default broadcastConfig
