import { LoadFailedEl } from '../elements/load-failed'

const LoadFailed = () => {
  return (
    <LoadFailedEl>
      <p>Load Failed</p>
    </LoadFailedEl>
  )
}

export default LoadFailed
