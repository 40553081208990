/* eslint-disable import/prefer-default-export */
import { motion } from 'framer-motion'
import styled from 'styled-components'

import { accent, background2 } from '../../../styles/colors'
import { center, hFlex, square, vFlex } from '../../../styles/style-helpers'

export const BroadcastClassEl = styled(motion.div)`
  display: block;
  height: calc(100% - 1em);
  width: calc(60vw + 2em);
  ${hFlex};
  padding: 0 1em;
  margin: 0.5em 0;

  .content {
    background: ${background2};
    height: 100%;
    width: 100%;
    ${vFlex};
    justify-content: space-between;
    border-radius: 0.5em;
    padding-bottom: 1em;

    .cover {
      position: relative;
      ${square('calc(60vw - 2em)')};
      margin: 1em 0;
      background: #333;
      border-radius: 0.5em;
      overflow: hidden;

      > div {
        height: 100%;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .type {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0.75em;
        padding: 0.5em 0.75em;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(20px);
        border-radius: 0.25em;
        letter-spacing: 0.025em;
        text-transform: uppercase;
      }
    }

    > p {
      padding: 0 1em;
      width: 100%;
      font-weight: bold;
      line-height: 1.33;

      &.artist {
        font-weight: normal;
        margin: 0.5em 0 1em;

        span {
          color: ${accent};
        }
      }
    }

    .row {
      width: 100%;
      ${hFlex};
      justify-content: space-between;
      padding: 0 1em;

      .MuiIconButton-root {
        background: #555;
        border-radius: 0.25em;
        padding: 0.25em !important;
      }
    }

    .info {
      width: 100%;
      margin: 1em 0;
      opacity: 0.6;

      > .row {
        margin: 0.75em 0;
        justify-content: flex-start;

        .MuiSvgIcon-root {
          font-size: 1.25em;
          margin-right: 0.5em;
        }
      }
    }

    .fee {
      opacity: ${({ hideFee }) => (hideFee ? 0 : 1)};

      p {
        width: fit-content;
        position: relative;
        color: #c7c7c7;

        &:after {
          content: '';
          ${center};
          width: 100%;
          height: 1px;
          background: #c7c7c7;
        }
      }

      h5 {
        color: #e7e7e7;

        span {
          margin-left: 0.5em;
          font-size: 0.64em;
          letter-spacing: 0.025em;
        }
      }
    }
  }
`
