import broadcastConfig from '../config/broadcast-config'

const broadcastUtils = {
  getByRoute: (query) =>
    broadcastConfig.types.find((type) => type.route === query),
  getByType: (query) =>
    broadcastConfig.types.find(({ type }) => type === query),
}

export default broadcastUtils
