import { getTimeZones } from '@vvo/tzdb'
import * as localforage from 'localforage'
import { extendPrototype } from 'localforage-observable'
import { DateTime } from 'luxon'
import Observable from 'zen-observable'

import ipInfo from '../api/misc/ip-info'
import { USER_PREFERENCES_KEY } from '../app/dashboard/settings/preferences/stores/peferences-store'
import isServer from './is-server'

const localforageWithObservables = extendPrototype(localforage)

const timeZones = getTimeZones()

let timezone = ''
let timezoneFull = ''

const setupTimezoneParser = async () => {
  if (isServer()) {
    return
  }

  localforageWithObservables.newObservable.factory = function createObservable(
    subscribeFn,
  ) {
    return new Observable(subscribeFn)
  }

  localforage
    .getItem(USER_PREFERENCES_KEY)
    .then(async (preferences) => {
      const targetTz = preferences?.timeZone || (await ipInfo())?.timeZone
      timezoneFull = targetTz

      timezone =
        timeZones.find((tz) => tz.name === targetTz)?.abbreviation || ''
    })
    .catch(() => {})

  await localforageWithObservables.ready()

  const observable = localforageWithObservables.newObservable({
    key: USER_PREFERENCES_KEY,
  })

  observable.subscribe({
    next(args) {
      const newTz = args.newValue?.timeZone
      timezone = timeZones.find((tz) => tz.name === newTz)?.abbreviation || ''
    },
  })
}

setupTimezoneParser()

const createDate = (ms) =>
  timezoneFull
    ? DateTime.fromMillis(Number(ms)).setZone(timezoneFull)
    : DateTime.fromMillis(Number(ms))

const tzParser = (str = '') => str.replace('ZZZZ', `'${timezone}'`)

const relative = (ms = 0, options) => createDate(ms).toRelative(options)

const time = (ms = 0) => createDate(ms).toFormat(`h:mma`)

const short = (ms = 0) => createDate(ms).toFormat(`hh:mm a, d MMM`)

const dayDate = (ms = 0) => createDate(ms).toFormat(`dd ccc`)

const dateShort = (ms = 0) => createDate(ms).toFormat(`d MMM`)

const dateMed = (ms = 0) => createDate(ms).toFormat(`d MMMM yyyy`)

const dateFull = (ms = 0) =>
  createDate(ms).toFormat(tzParser(`d MMMM yyyy ZZZZ`))

const full = (ms = 0) =>
  createDate(ms).toFormat(tzParser(`hh:mm a, d MMM yyyy ZZZZ`))

const day = (ms = 0) => createDate(ms).toFormat(`ccc`)
const addMonths = (date1, m = 1) => {
  return createDate(date1).plus({ months: m })
}
const dateParser = {
  addMonths,
  dateFull,
  dateMed,
  dateShort,
  day,
  dayDate,
  full,
  relative,
  short,
  time,
  timezone: () => timezone,
}

export default dateParser
